import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import OffplanNewBanner from "../components/OffplanNewBanner/OffplanNewBanner"
import ImageContentComponent from "../components/ImageContentComponent/ImageContentComponent"
import TopInvestmentArea from "../components/TopInvestmentArea/TopInvestmentArea"
import OffplanDevelopers from "../components/OffplanDevelopers/OffplanDevelopers"
import { Container } from "react-bootstrap"
import OffplanConsultant from "../components/OffplanConsultant/OffplanConsultant"
import PodcastSlider from "../components/PodcastSlider/PodcastSlider"
import OffplanContactDetails from "../components/OffplanContactDetails/OffplanContactDetails"
import { performSearches, removeDataAttributes } from "../components/Common/utils"
import qs from "qs"
import logoBlack from "../images/logo-white.svg"
import Accordion from "../components/AccordianComponent/Accordion"
import SEO from "../components/seo"
import { GetTransformedUrl } from "../common/stb-jamstack-ggfxclient/client"


const OffplanLanding = ({ data,pageContext }) => {
  const [pageData, setPageData] = useState(data?.strapiPage)
  const [loading, setLoading] = useState(true)
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(false)
  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false) // since its string

  // Execute the searches
 

  const newsData = data?.allStrapiBlog?.edges

  const contactData =
    data?.allStrapiSiteConfig?.edges?.length > 0
      ? data?.allStrapiSiteConfig?.edges[0]?.node
      : ""

  const allpodCastData = []
  const podCastData = []

  let count = 0
  newsData?.filter(item => {
    if (item?.node?.blog_categories?.length > 0) {
      item?.node?.blog_categories?.filter(blog => {
        if (blog.slug === "podcasts") {
          podCastData.push(item.node)
        }
      })
    }
  })

  podCastData?.filter(item=>{
    if(item?.blog_topics?.length>0){
      item?.blog_topics?.filter(blog=>{
        if(blog.slug === "off-plan"&& count<8){
          count += 1
          allpodCastData.push(item)
        }
      })
    }
  })

  const developerData =
    data?.allStrapiOffplanDeveloper?.edges?.length > 0
      ? data?.allStrapiOffplanDeveloper?.edges[0]?.node
      : ""
  

  const teamData = data?.allStrapiTeam?.edges
  const offplanConsultant = []
  teamData?.filter((item, index) => {
    if (item && item.node && item.node.team_member) {
      item.node.team_member.filter(member => {
        if (member.designation === "Offplan & Investment") {
          offplanConsultant.push(item)
        }
      })
    }
  })

  //preview setup
  const query = qs.stringify(
    {
      populate: {
        ggfx_results:"*",
        choose_menu: "*",
        seo:"*",
        select_popular_search:"*",
        banner:{
          populate:{
            image:"*",
            cta_1_link:{
              populate:{
                parent:"*"
              }
            },
            cta_2_link:{
              populate:{
                parent:"*"
              }
            },
            cta_3_link:{
              populate:{
                parent:"*"
              }
            }
          }
        },
        add_page_modules: {
          on: {
            
            "page-modules.accordion":{
              populate:{
                add:{
                  populate:"*"
                }
              }
            },
            "page-modules.developer-logo":{
              populate:{
                items:{
                  populate:{
                    image:"*"
                  }
                }
              }
            },
            "page-modules.two-columns":{
              populate:{
               image:"*",
               menu:{
                 populate:{
                   parent:"*"
                 }
               }
              }
            },
            "page-modules.custom-modules":{
              populate:{
                select_module:"*"
              }
            },
            "page-modules.primary-module":{
              populate:{
                items:{
                  populate:{
                    image:"*",
                      cta:{
                        link:{
                          populate:{
                            parent:"*"
                          }
                        }
                      }
                    }
                  }
               }
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/pages/${urlParams.get('strapi_id')}?${query}`

    // const apiUrl=process.env.GATSBY_STRAPI_SRC+'/api/events/'+urlParams.get('strapi_id')+'?populate[0]=modules&populate[1]=banner.cta_1_link&populate[2]=banner.cta_2_link&populate[3]=modules.icon_content&populate[4]=modules.icon_content.icon_module.image&populate[5]=form_content&populate[6]=ggfx_results&populate[12]=banner.image'
    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
    setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        setEnablePreview(false)

        let myPrevData;
        if(pageData){
          myPrevData = pageData
        } else {
          myPrevData = {};
          myPrevData.banner.banner_image = {}
          myPrevData.add_page_modules=[]
          myPrevData.id = urlParams.get('strapi_id')
        }

        if(previewData){
          myPrevData.banner = previewData?.banner
          myPrevData.banner.image = previewData?.banner?.image
          myPrevData.banner.banner_content = previewData?.banner?.banner_content
          myPrevData.banner.banner_title = previewData?.banner?.banner_title
          myPrevData.banner.cta_1_title = previewData?.banner?.cta_1_title
          myPrevData.banner.cta_1_custom_link = previewData?.banner?.cta_1_custom_link
          myPrevData.banner.cta_1_link = previewData?.banner?.cta_1_link
          myPrevData.banner.cta_2_link = previewData?.banner?.cta_2_link
          myPrevData.banner.cta_2_title = previewData.banner.cta_2_title
          myPrevData.banner.cta_2_custom_link = previewData?.banner?.cta_2_custom_link
          myPrevData.banner.cta_3_custom_link = previewData?.banner?.cta_3_custom_link
          myPrevData.banner.cta_3_title = previewData?.banner?.cta_3_title
          myPrevData.title = previewData?.title
          myPrevData.layout = previewData?.layout
          myPrevData.select_popular_search = previewData.select_popular_search
          myPrevData.slug = previewData?.slug
          myPrevData.mobile_image = previewData?.mobile_image
          myPrevData.choose_menu = previewData?.choose_menu

          myPrevData.ggfx_results = previewData?.ggfx_results
          myPrevData.add_page_modules=previewData?.add_page_modules
          setPageData(pageData => ({ ...pageData, myPrevData }));
          sessionStorage.setItem("previewMeta",JSON.stringify(previewData?.seo))
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
      sessionStorage.removeItem("previewMeta")
    }

  },[])

  if(loading){
    return (
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" class="new-loader">
            <img
              className="logo-white loader-logo"
              src={logoBlack}
              alt="logo"
            />
          </div>
        </div>
      </section>
    )
  }

  if(enablePreview){
    return null
  }

  //


let queryValue = ""
if(typeof window !== "undefined"){
  const getQuery = new URLSearchParams(window.location.search);
  if(getQuery.get("open-form")){
    queryValue=getQuery.get("open-form")
  }
}


  return (
    <Layout dark popularSearch={pageData?.select_popular_search?.title}>
      <OffplanNewBanner queryValue={queryValue} isPreviewEnabled={isPreviewEnabled} ratingData={contactData} pageData={pageData} />
      {pageData?.add_page_modules?.map((item, i) => {
        return (
          <div>
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_TWO_COLUMNS" || 
              item?.__component === "page-modules.two-columns") && (
              <ImageContentComponent
                isOffplanLanding
                isPreviewEnabled={isPreviewEnabled}
                data={item}
                pageData={pageData}
              />
            )}
            {(item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_ACCORDION" ||
              item?.__component === "page-modules.accordion") && (
              <Accordion
                isPreviewEnabled={isPreviewEnabled}
                add_toggle={item?.add}
                title={item?.title}
                content={item?.content}
                isChangeBg
              />
            )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_PRIMARY_MODULE" || 
              item?.__component === "page-modules.primary-module") && (
              <Container>
                <TopInvestmentArea isOffplanLanding isPreviewEnabled={isPreviewEnabled} areasData={item} pageData={pageData} />
              </Container>
            )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_DEVELOPER_LOGO" || 
              item?.__component === "page-modules.developer-logo")&&( 
                <Container>
                  <OffplanDevelopers
                    isOffplanLanding
                    moduleData={item}
                    developers={item?.items}
                    isPreviewEnabled={isPreviewEnabled}
                  />
                </Container>
              )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES" || 
              item?.__component === "page-modules.custom-modules") &&
              item?.select_module === "consultants" && (
                <OffplanConsultant
                  itemData={item}
                  teamData={offplanConsultant}
                  pageData={pageData}
                  isPreviewEnabled={isPreviewEnabled}
                />
              )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES" || 
              item?.__component === "page-modules.custom-modules") &&
              item?.select_module === "podcasts" && allpodCastData?.length>0 &&(
                <PodcastSlider
                  podCastData={allpodCastData}
                  pageData={pageData}
                  itemData={item}
                  isPreviewEnabled={isPreviewEnabled}
                />
              )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES" || 
              item?.__component === "page-modules.custom-modules") &&
              item?.select_module === "developers_contact" &&  contactData?.contact_info && (
                <OffplanContactDetails
                  contactData={contactData?.contact_info}
                  moduleData={item}
                  isPreviewEnabled={isPreviewEnabled}
                />
              )}
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  let getImg= PageData?.banner?.image?.url? PageData?.banner?.image?.url:""
  let findImage = getImg?.substring(getImg?.indexOf("i"));

  let imageExtension=getImg?getImg?.split("."):""
  let ext=imageExtension?.length>0?imageExtension?.pop():""

  let imageUrl= PageData?.banner?.image?.url

//new ggfx image
  const getImage =
  PageData?.banner?.image?.url
      ? PageData?.banner?.image?.url.replace(
          "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
          "",
        )
      : ""
  const filterImage =
    getImage && PageData?.ggfx_results?.length > 0
      ? PageData?.ggfx_results?.filter((item) => item.src_cftle === getImage)
      : ""

  // filter image with image size
  const getOGImg =
    filterImage?.length > 0 && filterImage[0].transforms?.length > 0
      ? filterImage[0].transforms.filter((item) => item.transform === "600x400"&&item.format===ext)
      : ""

   //to get image in uploaded format   
  const ogRenderNew = ()=>{
    GetTransformedUrl({
      srcCftle: findImage,
      format: ext,
      transform:"600x400",
      id: PageData.strapi_id,
      field: "metaog",
      contentType: "page"
    })
  }

  useEffect(()=>{
    if(!getOGImg?.length>0)
        ogRenderNew()
  },[]) 

  //----
  const pageImage=getOGImg?.length>0?getOGImg[0].url:imageUrl

  const metaTitle = PageData?.seo?.metaTitle ? `${ PageData?.seo?.metaTitle} | ` :"";
  const pageTitle = PageData?.title ? `${PageData?.title} | `:""


  //preview seo dynamic updates

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }


  const [preMetaTitle, setPreMetaTitle] = useState(metaTitle)


  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== "undefined" && isPreview) {
        try {
          const sessionMetaData = sessionStorage.getItem("previewMeta");
          if (sessionMetaData) {
            const parsedData = JSON.parse(sessionMetaData);
          if(parsedData?.metaTitle){
            setPreMetaTitle(parsedData?.metaTitle)
          }
        }
      } catch (error) {
        console.error("Error parsing session storage data:", error);
      }
    }
  }, 4000);

  // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [isPreview]);

  useEffect(() => {
    if(isPreview){
    document.title =  `${preMetaTitle} | haus & haus`;
    }
  }, [preMetaTitle]);

  //

  
  return (
      <SEO title={(isPreview && preMetaTitle) ? preMetaTitle : metaTitle ? metaTitle : pageTitle} 
      description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl={pageImage}
      />
  )
}


export default OffplanLanding

export const query = graphql`
  query ($page_id: String) {
    allStrapiOffplanDeveloper {
      edges {
        node {
          title
          description {
            data {
              description
            }
          }
          developer_logo {
            image {
              url
            }
            custom_link
          }
        }
      }
    }

    allStrapiTeam(
    filter: { publish: { eq: true } }
    sort: {fields: sort, order: ASC}
    ) {
     edges {
        node {
          image {
            url
          }
          ggfx_results {
            src_cftle
            transforms {
              url
              transform
              format
            }
          }
          Languages_Spoken {
            strapi_json_value
          }
          slug
          strapi_id
          designation
          name
          sort
          team_member {
            slug
            designation
          }
          choose_areas {
            title
            slug
          }
        }
      }
    }

    allStrapiSiteConfig {
      edges {
        node {
          offplan_ratetext
          offplan_rating
          contact_info {
            title
            whatsapp_num
            email
            description {
              data {
                description
              }
            }
          }
        }
      }
    }

    allStrapiBlog(
      filter: { publish: { eq: true } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          slug
          strapi_id
          date
          ggfx_results {
            src_cftle
            transforms {
              url
              transform
              format
            }
          }
          tile_image {
            url
          }
          blog_categories {
            blog_category
            slug
          }
          blog_topics {
            slug
            topic_name
          }
        }
      }
    }

    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          __typename
          id
          description {
            data {
              description
            }
          }
          title
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_DEVELOPER_LOGO {
            __typename
            id
            title
            description {
              data {
                description
              }
            }
            items {
              image {
                url
              }
              custom_link
            }
          }

        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          __typename
          id
          add {
            content {
              data {
                content
              }
            }
            title
          }
          title
          content {
            data {
              content
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_PRIMARY_MODULE {
          __typename
          id
          title
          content {
            data {
              content
            }
          }
          items {
            image {
              url
            }
            title
            sub_title
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES {
          __typename
          id
          title
          content {
            data {
              content
            }
          }
          select_module
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_TWO_COLUMNS {
          __typename
          id
          cta_label
          description {
            data {
              description
            }
          }
          menu {
            slug
            strapi_parent {
              slug
            }
          }
          custom_link
          custom_link1
           cta_label1
          menu1 {
            slug
            strapi_parent {
              slug
            }
          }
          strapi_component
          title
          layout
          image {
            url
          }
        }

        
      }
    }
  }
`
